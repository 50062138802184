import { AriaTooltipAndTrigger, FbFormCheckbox } from '@decernointernal/fb-interna-komponenter';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { NyttAerendeContext } from '../context/NyttAerendeContext';

interface IAerFaerdigutbildad {
  readonly label: string;
  readonly tooltipText: string;
  readonly tooltipClassName?: string;
}

export const AerFaerdigutbildad: FC<IAerFaerdigutbildad> = ({ label, tooltipText, tooltipClassName }) => (
  <NyttAerendeContext.Consumer>
    {context =>
      context && (
        <div className="flex items-center">
          <FbFormCheckbox
            id="aerFaerdigutbildad"
            label={label}
            defaultChecked={context.nyttAerendeState.aerFaerdigutbildad}
            onChange={(_: string, checked: boolean) => {
              context.setNyttAerendeState(prevState => {
                return { ...prevState, aerFaerdigutbildad: checked };
              });
            }}
          />
          <AriaTooltipAndTrigger tooltips={[{ text: tooltipText, position: 'top', className: tooltipClassName }]}>
            <div className="relative pl-1">
              <FontAwesomeIcon icon={faInfoCircle} className="peer text-fb-color-info" size="xs" />
            </div>
          </AriaTooltipAndTrigger>
        </div>
      )
    }
  </NyttAerendeContext.Consumer>
);
