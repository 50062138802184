import { AriaTooltipAndTrigger, FbFormInput, FbFormRadiobuttonGroup } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { aerendeMedarbetareKontorPropTypEnum } from 'domain/aerendeMedarbetareKontorPropTypDomain';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectAerendeQry } from '../../qryAerende';
import { IInformationSektionProps } from '../IInformationProps';
import { sektionHoc } from './sektionHoc';

export const Medarbetartexter: FC<IInformationSektionProps> = ({ aerendePK }) => {
  const aerende = useSelector(selectAerendeQry(aerendePK));

  return (
    <>
      {RemoteDataFunctions.hasData(aerende) && (
        <>
          <div className="pb-6">
            <FbFormInput
              id="titel"
              label="Titel"
              disabled={true}
              tooltip="För att ändra Titel, kontakta HR-support efter att kontot skapats."
              tooltipMaxwidth={23.75 * 16}
              className="w-96"
              defaultValue={aerende.data.Titel ?? ''}
            />
          </div>
          <div className="flex items-start">
            {sektionHoc(FbFormRadiobuttonGroup, { aerendeId: aerendePK.AerendeId })({
              id: aerendeMedarbetareKontorPropTypEnum.VISA_INTE_PAA_KONTORETSSIDA,
              label: 'Visa medarbetaren på webben',
              groupname: 'visaPaaWebben',
              radioButtons: [
                {
                  id: 'visaPaaWebben1',
                  value: 'false',
                  label: 'Ja',
                  defaultChecked: !aerende.data.VisaIntePaaKontoretsSida,
                },
                {
                  id: 'visaPaaWebben0',
                  value: 'true',
                  label: 'Nej',
                  defaultChecked: aerende.data.VisaIntePaaKontoretsSida,
                },
              ],
            })}
            <AriaTooltipAndTrigger
              tooltips={[
                {
                  text: 'Välj Nej för att dölja medarbetaren på kontorets sida på webben',
                  position: 'top',
                  className: 'max-w-[380px]',
                },
              ]}
            >
              <div className="relative pl-1 leading-5">
                <FontAwesomeIcon icon={faInfoCircle} className="peer text-fb-color-info" size="xs" />
              </div>
            </AriaTooltipAndTrigger>
          </div>

          <div>
            <br />
            <p>Medarbetaren skriver själv sin presentation i FasIT under Personliga inställningar.</p>
            <br />
            <p>Bild på medarbetaren laddas upp från fliken Checklistor.</p>
          </div>
        </>
      )}
    </>
  );
};
