import {
  AriaTooltipAndTrigger,
  clickOutside,
  dateToAAAAMMDD,
  FbButton,
  FbPopover,
} from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions, RemoteDataFunctions } from '@decernointernal/websd.shared';
import { faClose, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import aerendemallTypEnum from 'domain/aerendemallTyp';
import { aerendeStatusEnum } from 'domain/aerendeStatus';
import { AerendeDTO, AerendePK, AerendeStatusVO } from 'generated-models/anstallningsportalen';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { homeRoute } from 'store/location';
import { useAppDispatch } from 'store/store';
import { VOCreate } from 'utils/ValueObjectHelpers';
import {
  changeAerendeStatusAction,
  removeChangeAerendeStatusAction,
  selectChangeAerendeStatusCmd,
} from './cmdChangeAerendeStatus';
import { aerendeQryAction, selectAerendeQry } from './qryAerende';

export const AerendeHeader: React.FC<{ aerendePK: AerendePK }> = ({ aerendePK }) => {
  const dispatch = useAppDispatch();
  const aerende = useSelector(selectAerendeQry(aerendePK));
  const changeAerendeStatus = useSelector(selectChangeAerendeStatusCmd(aerendePK));

  const openPopoverButtonRef = React.useRef<HTMLDivElement | null>(null);
  const {
    ref: removeAerendePopoverRef,
    isComponentVisible: removeAerendeExpandedState,
    setIsComponentVisible: setRemoveAerendeExpandedState,
  } = clickOutside(false, [openPopoverButtonRef]);

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(aerende) || RemoteDataFunctions.isStale(aerende)) {
      dispatch(aerendeQryAction(aerendePK));
    }
  }, [aerende, aerendePK, dispatch]);

  useEffect(() => {
    if (CommandFunctions.isSuccess(changeAerendeStatus)) {
      dispatch(homeRoute());
      dispatch(removeChangeAerendeStatusAction(changeAerendeStatus.data));
    }
  }, [changeAerendeStatus, dispatch]);

  const removeAerende = () => {
    if (!CommandFunctions.isExecuting(changeAerendeStatus)) {
      dispatch(
        changeAerendeStatusAction({
          PrimaryKey: aerendePK,
          AerendeStatus: VOCreate<AerendeStatusVO>({ AerendeStatus: aerendeStatusEnum.BORTTAGEN }),
        })
      );
    }
  };

  const getAerendeDatum = (aerende?: AerendeDTO): JSX.Element => {
    let date: Date | null | undefined;
    let rubrik: string = '';

    if (aerende) {
      if (aerende.AerendemallTypVO.AerendemallTyp === aerendemallTypEnum.AVSLUT) {
        date = aerende.Slutdatum;
        rubrik = 'Sista dag på kontoret';
      } else if (aerende.AerendemallTypVO.AerendemallTyp === aerendemallTypEnum.NY) {
        date = aerende.Startdatum;
        rubrik = 'Första dag på kontoret';
      }
    }

    return (
      <div className="grow">
        <h5 className="items-center pb-1.5">{rubrik}</h5>
        <p className="items-center">{date ? dateToAAAAMMDD(date) : ''}</p>
      </div>
    );
  };

  const currentAerende: AerendeDTO | undefined = RemoteDataFunctions.hasData(aerende) ? aerende.data : undefined;
  return currentAerende ? (
    <>
      <div className="p-4 bg-fb-color-blue-light w-full shadow-md flex justify-between z-10">
        <div className="grow">
          <h5 className="items-center pb-1.5">Ärende</h5>
          <p className="items-center">{currentAerende.AerendemallRubrik}</p>
        </div>
        <div className="grow">
          <h5 className="items-center pb-1.5">{`${currentAerende.Foernamn} ${currentAerende.Efternamn}`}</h5>
          <p className="items-center">{currentAerende.RollNamn}</p>
        </div>
        {getAerendeDatum(currentAerende)}
        <div className="relative flex">
          <FbButton
            type="secondary"
            text="Stäng"
            icon={faClose}
            onClick={() => dispatch(homeRoute())}
            className="mr-2"
          />
          <AriaTooltipAndTrigger
            tooltips={[
              {
                text: 'Du kan välja att ta bort ärendet om du av någon anledning ej vill fullfölja det. När ärendet tas bort försvinner det från systemet.',
                position: 'left',
                className: 'max-w-[290px]',
              },
            ]}
          >
            <div ref={openPopoverButtonRef}>
              <FbButton
                type="secondary"
                text="Ta bort ärende"
                icon={faTrashCan}
                disabled={
                  RemoteDataFunctions.isSuccess(aerende) &&
                  aerende.data.AerendeStatusVO.AerendeStatus === aerendeStatusEnum.BORTTAGEN
                }
                onClick={() => setRemoveAerendeExpandedState(!removeAerendeExpandedState)}
              />
            </div>
          </AriaTooltipAndTrigger>
          <FbPopover
            header="Är du säker på att du vill ta bort ärendet?"
            showPopover={removeAerendeExpandedState}
            popoverSize="small"
            popoverRef={removeAerendePopoverRef}
          >
            <p>All information du fyllt i kommer att tas bort och ärendet kommer att försvinna.</p>
            <div className="flex justify-end px-4 pb-4 mt-6">
              <FbButton
                className="mr-4"
                text={'Avbryt'}
                type={'secondary'}
                buttonState={CommandFunctions.isExecuting(changeAerendeStatus) ? 'waiting' : 'default'}
                onClick={() => setRemoveAerendeExpandedState(false)}
              />
              <FbButton text={'Ja, ta bort'} type={'primary'} onClick={removeAerende} />
            </div>
          </FbPopover>
        </div>
        <div></div>
      </div>
    </>
  ) : (
    <div />
  );
};
