import { FC } from 'react';
import { NyttAerendeContext } from '../../context/NyttAerendeContext';
import { AerFaerdigutbildad } from '../../shared-components/AerFaerdigutbildad';
import { Header } from '../../shared-components/Header';
import { Personnamn } from '../../shared-components/Personnamn';
import { Personnummer } from '../../shared-components/Personnummer';
import { PlaneradFoerstaArbetsdag } from '../../shared-components/PlaneradFoerstaArbetsdag';

export const AnstaellEnBlivandeFastighetsmaeklare: FC = () => {
  return (
    <NyttAerendeContext.Consumer>
      {context =>
        context && (
          <>
            <AerFaerdigutbildad
              label="Kandidaten är godkänd på alla tentor och anvarsförsäkring ska tecknas."
              tooltipText="Välj Mäklarpraktikant om kandidaten inte är färdigutbildad"
              tooltipClassName="max-w-[220px]"
            />

            {context.nyttAerendeState.aerFaerdigutbildad && (
              <>
                <Header rubrik="Personinformation" className="mt-6" />

                <Personnummer />
                <Personnamn />
                <PlaneradFoerstaArbetsdag />
              </>
            )}
          </>
        )
      }
    </NyttAerendeContext.Consumer>
  );
};
